import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SupportPage = () => (
  <Layout>
    <SEO title="Az Annyiavilág Támogatása" />
    <h1>Az Annyiavilág Támogatása</h1>
    <p class="support-notify">Nincs zavaró reklám, és nem is lesz. Az oldal a <b>2020-as pandémia közepette</b> indult abban a tudatban, hogy bárkinek szüksége lehet mások segítségére, és azóta teljesen önkéntes alapon működik, hogy elsősorban segítse a rajta megtalálható alapítványokat, illetve egy egyszerű és gyors weboldalt biztosítson különböző lehetőségek megkeresésére (szolgáltatások, hírek, játékok, térképek, stb.).</p>
    <p class="support-notify">Ha elnyeri tetszésedet a szolgáltatásunk, kérlek, segítsd és támogasd a munkánkat, illetve a fenntartási költségeinket:</p>
    
    <div className="content-wrapper">
        <h3 className="health-icon"><i className="fas fa-plus"></i> Egyszerű</h3>
        <div className="header-wrapper">
            <div className="cardify-content-wrapper support">
                <a className="cardify-content-card" href="https://www.buymeacoffee.com/mznaksco" target="_blank">
                    <h5 className="main-content-card-title"><i class="fas fa-mug-hot buymeacoffee-icon"></i> Buy Me A Coffee</h5>
                    <p className="cardify-content-alt">Annyiavilág</p>
                </a>
            </div>
        </div>
    </div>

    <div className="content-wrapper">
        <h3 className="health-icon"><i className="fas fa-plus"></i> Kripto</h3>
        <div className="header-wrapper">
            <div className="cardify-content-wrapper support">
                <div className="cardify-content-card">
                    <h5 className="main-content-card-title"><i class="fab fa-bitcoin btc-icon"></i> Bitcoin (BTC)</h5>
                    <p className="cardify-content-alt">bc1qszwgs2sn0d6se9eq9njhwkqjdg8w66fr5z3rh7</p>
                </div>
                <div className="cardify-content-card">
                    <h5 className="main-content-card-title"><i class="fab fa-ethereum eth-icon"></i> Ethereum (ETH)</h5>
                    <p className="cardify-content-alt">0x8C911311a66Aab3C3f565B3EC878c53e831740D8</p>
                </div>
            </div>
        </div>
    </div>
  </Layout>
)

export default SupportPage
